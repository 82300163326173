import React from 'react'
import { graphql } from 'gatsby'

import GDPR from '../components/Pages/GDPR'

const GDPRPage = ({ data, location }) => {
  const localeID = 'de_DE'

  return <GDPR location={location} localeID={localeID} data={data} />
}

export default GDPRPage

export const pageQuery = graphql`
  query GDPRQueryDE {
    wpPage(slug: { eq: "gdpr" }, locale: { id: { eq: "de_DE" } }) {
      title
      content
    }
  }
`
